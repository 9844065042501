
.delivery-advertisement-container {
  height: 95%;
  display: flex;
  flex-direction: column;
  padding: 20px 0 0 22px;
  ::-webkit-scrollbar {
    display: none;
  }
  .advertisement-title {
    margin: 19px 0;
  }
  ::v-deep
    .genera-breadcrumb
    .el-breadcrumb__item:last-child
    .el-breadcrumb__inner {
    color: rgb(235, 235, 235);
  }
  .advertisement-main {
    height: 95%;
    overflow: hidden;
    box-shadow: 0px 0px 10px 0px rgba(11, 12, 28, 0.04);
    border-radius: 6px;
    padding: 11px 20px 0 0;
    display: flex;
    flex-direction: column;
    .main-btn {
      display: flex;
      justify-content: flex-start;
    }

    .ad-money {
      display: flex;
    }
    .el-table {
      ::v-deep .el-checkbox__inner {
        background: transparent;
      }
      background: none;
      ::v-deep th.el-table__cell.is-leaf {
        border-bottom: none !important;
      }
      ::v-deep .el-table__body-wrapper {
        background: #070932;
      }
      ::v-deep td.el-table__cell {
        border-bottom: 1px solid #262963;

        &:first-child {
          border-left: 1px solid #262963;
        }

        &:last-child {
          border-right: 1px solid #262963;
        }
      }
    }
    ::v-deep .el-table__body tr:hover > td {
      background-color: #0c0e3f !important;
    }
    ::v-deep .el-table__fixed-right-patch {
      width: 0 !important;
      height: 0 !important;
    }
    ::v-deep .el-table__fixed-right::before,
    .el-table__fixed::before {
      content: none;
    }
    ::v-deep .el-table--border::after,
    .el-table--group::after,
    .el-table::before {
      background: none;
    }
    .pages-center {
      background: none;
      padding: 0px 0px !important;
    }
    ::v-deep .el-pagination {
      .btn-next,
      .btn-prev {
        background: none;
      }
    }
  }
}
